import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, Radio, Row} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const TwentyOneStep = ({question}) =>{

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            // navigate('/questions/partner/21')
            navigate('/buy-service')
        }else if (!location.pathname.includes('partner') && info.apply_for_spouse==='1'){
            navigate('/partner')
        }else if (info.apply_for_spouse==='2'){
            navigate('/buy-service',{state:info})
            localStorage.setItem('info',JSON.stringify(info))
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/20')
        } else {
            navigate('/questions/20')
        }
    }

    const onChange = (e) => {
        setInfo({...info,apply_for_spouse: e.target.value});
    };

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={info.apply_for_spouse}>
                <Row gutter={[12,12]}>
                    <Col lg={24}><CustomRadioButton value={'1'}
                                                    text={'حتما'}
                    /></Col>
                    <Col lg={24}><CustomRadioButton value={'2'}
                                                    text={'نه تو فعلا با همین اطلاعات ببین چیکار میتونی بکنی'}
                    /></Col>
                </Row>
            </Radio.Group>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={info.apply_for_spouse==='1'?'مرحله بعد':'تایید نهایی'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default TwentyOneStep