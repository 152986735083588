import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import {ConfigProvider} from 'antd';
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

const client = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={client}>
            <BrowserRouter>
                <ConfigProvider direction="rtl">
                    <App/>
                </ConfigProvider>
            </BrowserRouter>
            <ReactQueryDevtools/>
        </QueryClientProvider>
    </React.StrictMode>
);

reportWebVitals();
