import {useState} from "react";
import {Checkbox, Col, Input, Row, Select} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const TwelfthStep = ({question}) => {

    const [data, setData] = useState([
        {
            id: 1,
            title: 'تمکن بانکی',
            name: 'financial_ability_tamakon_banki',
            value: 1,
            isChecked: false
        },
        {
            id: 2,
            title: 'ملک و زمین',
            name: 'financial_ability_melk_va_zamin',
            value: 2,
            isChecked: false
        },
        {
            id: 3,
            title: 'سهام',
            name: 'financial_ability_stocks',
            value: 3,
            isChecked: false
        },
        {
            id: 4,
            title: 'سرقفلی یا جواز',
            name: 'financial_ability_sar_ghofli',
            value: 4,
            isChecked: false
        },
        {
            id: 5,
            title: 'شرکت',
            name: 'financial_ability_sherkat',
            value: 5,
            isChecked: false
        },
        {
            id: 6,
            title: 'گردش مالی ماهیانه',
            name: 'financial_ability_gardesh_mali',
            value: 6,
            isChecked: false
        },
        {
            id: 7,
            title: 'دارایی غیرنقدی',
            name: 'financial_ability_daraie_ghir_naghdi',
            value: 7,
            isChecked: false
        },
        {
            id: 8,
            title: 'هیچکدام',
            name: 'financial_ability_nadaram',
            value: 8,
            isChecked: false
        },
    ])

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/13')
        } else {
            navigate('/questions/13')
        }
    }

    const prevPageHandler = () => {
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/11')
        } else {
            navigate('/questions/11')
        }
    }

    const onChangeCheck = (e, id) => {
        let newState = data.map(item => {
            if (item.id === id) {
                if (!item.isChecked) {
                    setInfo({...info, [item.name]: null})
                }
                return {...item, isChecked: !item.isChecked}
            }
            return item
        })
        setData(newState)
    }

    const tamakonBanki = (data) => {
        setInfo({...info, financial_ability_tamakon_banki: data})
    }

    const meterageZamin = (e) => {
        setInfo({
            ...info,
            financial_ability_melk_va_zamin: {...info.financial_ability_melk_va_zamin, meterage: e.target.value}
        })
    }

    const priceZamin = (data) => {
        setInfo({...info, financial_ability_melk_va_zamin: {...info.financial_ability_melk_va_zamin, price: data}})
    }

    const sarghofliSabeghe = (data) => {
        setInfo({...info, financial_ability_sar_ghofli: {...info.financial_ability_sar_ghofli, sabeghe: data}})
    }

    const sarghofliType = (data) => {
        setInfo({...info, financial_ability_sar_ghofli: {...info.financial_ability_sar_ghofli, type_faaliat: data}})
    }


    const sherkatYears = (data) => {
        setInfo({...info, financial_ability_sherkat: {...info.financial_ability_sherkat, tedad_sal_faaliat: data}})
    }


    const sherkatGardeshMali = (data) => {
        setInfo({...info, financial_ability_sherkat: {...info.financial_ability_sherkat, ave_gardesh_mali: data}})
    }

    return (
        <Row gutter={[12, 6]}>
            {data.map(item => {
                return (
                    <Row style={{width: '100%'}} gutter={[12, 6]}>
                        <Col lg={8}>
                            <Checkbox checked={item.isChecked}
                                      onChange={(e) => onChangeCheck(e, item.id)}>{item.title}</Checkbox>
                        </Col>
                        {item.id === 1 && item.isChecked && <Col lg={16}><Select
                            size={'large'}
                            placeholder={'ارزش'}
                            value={info?.financial_ability_tamakon_banki}
                            onChange={tamakonBanki}
                            options={[
                                {
                                    value: 1,
                                    label: 'تا 500 میلیون',
                                },
                                {
                                    value: 2,
                                    label: '500 میلیون تا 1 میلیارد',
                                },
                                {
                                    value: 3,
                                    label: '1 تا 2 میلیارد',
                                },
                                {
                                    value: 4,
                                    label: '2 میلیارد به بالا',
                                },
                            ]}
                        /></Col>}
                        {item.id === 2 && item.isChecked && <>
                            <Col lg={8}>
                                <Input
                                    size={'large'}
                                    placeholder={'متراژ'}
                                    suffix={'متر'}
                                    onChange={meterageZamin}
                                />
                            </Col>
                            <Col lg={8}><Select
                                size={'large'}
                                placeholder={'ارزش'}
                                value={info?.financial_ability_melk_va_zamin?.price}
                                onChange={priceZamin}
                                options={[
                                    {
                                        value: 1,
                                        label: 'تا 500 میلیون',
                                    },
                                    {
                                        value: 2,
                                        label: '500 میلیون تا 1 میلیارد',
                                    },
                                    {
                                        value: 3,
                                        label: '1 تا 2 میلیارد',
                                    },
                                    {
                                        value: 4,
                                        label: '2 میلیارد به بالا',
                                    },
                                ]}
                            /></Col>
                        </>}
                        {item.id === 4 && item.isChecked && <>
                            <Col lg={8}>
                                <Select
                                    size={'large'}
                                    placeholder={'سابقه'}
                                    value={info?.financial_ability_sar_ghofli?.sabeghe}
                                    onChange={sarghofliSabeghe}
                                    options={[
                                        {
                                            value: 1,
                                            label: '3-1 سال',
                                        },
                                        {
                                            value: 2,
                                            label: '5-3 سال',
                                        },
                                        {
                                            value: 3,
                                            label: '10-5 سال',
                                        },
                                        {
                                            value: 4,
                                            label: 'بیشتر از 10 سال',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col lg={8}><Select
                                size={'large'}
                                placeholder={'نوع فعالیت'}
                                value={info?.financial_ability_sar_ghofli?.type_faaliat}
                                onChange={sarghofliType}
                                options={[
                                    {
                                        value: 1,
                                        label: 'فعال است',
                                    },
                                    {
                                        value: 2,
                                        label: 'فعالیت ندارد',
                                    },
                                ]}
                            /></Col>
                        </>}
                        {item.id === 5 && item.isChecked && <>
                            <Col lg={8}>
                                <Select
                                    size={'large'}
                                    placeholder={'تعداد سال فعالیت'}
                                    value={info?.financial_ability_sherkat?.tedad_sal_faaliat}
                                    onChange={sherkatYears}
                                    options={[
                                        {
                                            value: 1,
                                            label: '3-1 سال',
                                        },
                                        {
                                            value: 2,
                                            label: '5-3 سال',
                                        },
                                        {
                                            value: 3,
                                            label: '10-5 سال',
                                        },
                                        {
                                            value: 4,
                                            label: 'بیشتر از 10 سال',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col lg={8}><Select
                                size={'large'}
                                placeholder={'میانگین گردش سالیانه'}
                                value={info?.financial_ability_sherkat?.ave_gardesh_mali}
                                onChange={sherkatGardeshMali}
                                options={[
                                    {
                                        value: 1,
                                        label: 'تا 500 میلیون',
                                    },
                                    {
                                        value: 2,
                                        label: '500 میلیون تا 1 میلیارد',
                                    },
                                    {
                                        value: 3,
                                        label: '1 تا 2 میلیارد',
                                    },
                                    {
                                        value: 4,
                                        label: '2 میلیارد به بالا',
                                    },
                                ]}
                            /></Col>
                        </>}

                    </Row>
                )
            })}
            <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '1rem'
            }}>
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />

            </div>
        </Row>
    )
}

export default TwelfthStep