import {Col, Row, Select} from 'antd';
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";
import {useEffect} from "react";



const SeventhStep = ({question})=>{

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/8')
        }else{
            navigate('/questions/8')
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/6')
        } else {
            navigate('/questions/6')
        }
    }

    const onChange = (value) => {
        setInfo({...info,ielts_score:value})
    };

    useEffect(()=>{
            document.addEventListener('keypress',(e)=>{
                if (e.key === "Enter") {
                    if (location.pathname.includes('partner')) {
                        navigate('/questions/partner/8')
                    } else {
                        navigate('/questions/8')
                    }
                }
            })
        return ()=>document.removeEventListener('keypress',(e)=>{
            if (e.key === "Enter") {
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/8')
                } else {
                    navigate('/questions/8')
                }
            }
        })
    },[])


    return (
        <div>
            <div>{question}</div>
            <Row>
                <Col lg={24}>
                <Select
                    size={'large'}
                    value={info.ielts_score}
                    placeholder={'انتخاب نمره'}
                    onChange={onChange}
                    options={[
                        {
                            value: '4',
                            label: '4',
                        },
                        {
                            value: '4.5',
                            label: '4.5',
                        },
                        {
                            value: '5',
                            label: '5',
                        },
                        {
                            value: '5.5',
                            label: '5.5',
                        },
                        {
                            value: '6',
                            label: '6',
                        },
                        {
                            value: '6.5',
                            label: '6.5',
                        },
                        {
                            value: '7',
                            label: '7',
                        },
                        {
                            value: '7.5',
                            label: '7.5',
                        },
                        {
                            value: '8',
                            label: '8',
                        },
                        {
                            value: '8.5',
                            label: '8.5',
                        },
                        {
                            value: '9',
                            label: '9',
                        },
                    ]}
                />
                </Col>
            </Row>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default SeventhStep