import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, List, Modal, Radio, Row, Select} from "antd";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import {v4 as uuidv4} from "uuid";
import {years} from "../../../utils/constants";

const {Option} = Select

const SixteenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resInfo, setResInfo] = useState({ country_code: null , tedad_bar: null , type_visa: null  , az_sal: null , ta_sal: null  } )
    const [times,setTimes]=useState('1')
    const {info, setInfo,data} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/17')
        }else{
            navigate('/questions/17')
        }
    }
    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/15')
        } else {
            navigate('/questions/15')
        }
    }


    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'no') {
            setShowModal(true)
        } else {
            setShowQuestion(true)
        }
    };

    const deleteListItemHandler = (id) => {
        // let items = data.filter(item => item.id !== id)
        // setData(items)
        let items = info?.foreign_travel?.filter(item => item.id !== id)
        setInfo({...info, foreign_travel: items?.length > 0 ? items : null})
    }

    const changeTimesHandler = (data)=>{
        setTimes(data)
        setResInfo({...resInfo,tedad_bar: data})
    }

    const filterOption = (input, option) => {
        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const countryChangeHandler = (code) => {
        setResInfo({...resInfo, country_code: String(code)})
    }

    const fromYearChange = (year) => {
        setResInfo({...resInfo, az_sal: year})
    }

    const toYearChange = (year) => {
        setResInfo({...resInfo, ta_sal: year})
    }

    const fromYear2Change = (year) => {
        setResInfo({...resInfo, az_sal2: year})
    }

    const toYear2Change = (year) => {
        setResInfo({...resInfo, ta_sal2: year})
    }

    const lastYearChange = (year) => {
        setResInfo({ country_code: resInfo?.country_code , tedad_bar: resInfo?.tedad_bar , type_visa: resInfo?.type_visa,akharin_bar:year})
    }


    const visaTypeChangeHandler = (value)=>{
        setResInfo({...resInfo,type_visa: value})
    }

    const visaType2ChangeHandler = (value)=>{
        setResInfo({...resInfo, type_visa2: value})
    }

    const addInfo = () => {
        setTimes('1')
        info?.foreign_travel ?
            setInfo({...info, foreign_travel: [...info?.foreign_travel, {id: uuidv4(), ...resInfo}]}) :
            setInfo({...info, foreign_travel: [{id: uuidv4(), ...resInfo}]})
        times === '1' && setResInfo({ country_code: null , tedad_bar: null , type_visa: null  , az_sal: null , ta_sal: null  })
        times === '2' &&  setResInfo({ country_code: null , tedad_bar: null , type_visa: null  , az_sal: null , ta_sal: null, type_visa2: null  , az_sal2: null , ta_sal2: null  })
        times === '3' && setResInfo({ country_code: null , tedad_bar: null , type_visa: null,akharin_bar:null})
    }

    const getCountryFromCode = (value) => {
        return data?.countries?.find(item => String(item.id) == value)?.title
    }

    const getFullYear = (value) => {
        return years.find(item => item?.label?.includes(value))?.label
    }

    const getVisaType = (value) => {
        if (value==='1') return 'تحصیلی'
        if (value==='2') return 'کاری'
        if (value==='3') return 'توریستی'
    }


    return (
        <div style={{textAlign: 'right'}}>
            {/*<div style={{marginTop:'3rem'}}>{question}</div>*/}
            {showQuestion || info?.foreign_travel ?
                <>
                    <div style={{fontSize: '12.5px', color: '#686868', marginBottom: '1rem'}}>لطفا اطلاعات زیر را کامل
                        کن
                    </div>
                    <Row gutter={[12, 12]}>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'انتخاب کشور'}
                                optionFilterProp="children"
                                showSearch
                                value={getCountryFromCode(resInfo?.country_code)}
                                filterOption={filterOption}
                                onChange={countryChangeHandler}
                            >
                                {data?.countries.map(item => {
                                    return <Option key={item.id} value={item.id}>{item.title}</Option>
                                })}
                            </Select>
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'چندبار'}
                                value={resInfo?.tedad_bar}
                                onChange={changeTimesHandler}
                                options={[
                                    {
                                        value: '1',
                                        label: '1 بار',
                                    },
                                    {
                                        value: '2',
                                        label: '2 بار',
                                    },
                                    {
                                        value: '3',
                                        label: 'بیش از 2 بار',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={times==='3' ? 12 : 24}>
                            <Select
                                size={'large'}
                                placeholder={'نوع ویزا'}
                                value={resInfo?.type_visa}
                                onChange={visaTypeChangeHandler}
                                options={[
                                    {
                                        value: '1',
                                        label: 'تحصیلی',
                                    },
                                    {
                                        value: '2',
                                        label: 'کاری',
                                    },
                                    {
                                        value: '3',
                                        label: 'توریستی',
                                    },
                                ]}
                            />
                        </Col>
                        {times !== '3' ?
                            <>
                            <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'از سال'}
                                value={resInfo?.az_sal}
                                onChange={fromYearChange}
                                options={years}
                            />
                        </Col>
                            <Col lg={12}>
                            <Select
                            size={'large'}
                            placeholder={'تا سال'}
                            value={resInfo?.ta_sal}
                            onChange={toYearChange}
                            options={[{label:'هنوز اقامت دارم', value:'1111'},...years]}
                            />
                            </Col>
                            </> :
                            <Col lg={12}>
                                <Select
                                    size={'large'}
                                    placeholder={'تاریخ آخرین بار'}
                                    value={resInfo?.akharin_bar}
                                    onChange={lastYearChange}
                                    options={years}
                                />
                            </Col>
                            }
                        {times === '2' && <> <Col lg={24}>
                            <Select
                                size={'large'}
                                placeholder={'نوع ویزا'}
                                value={resInfo?.type_visa2}
                                onChange={visaType2ChangeHandler}
                                options={[
                                    {
                                        value: '1',
                                        label: 'تحصیلی',
                                    },
                                    {
                                        value: '2',
                                        label: 'کاری',
                                    },
                                    {
                                        value: '3',
                                        label: 'توریستی',
                                    },
                                ]}
                            />
                        </Col>
                            <Col lg={12}>
                                <Select
                                    size={'large'}
                                    placeholder={'از سال'}
                                    value={resInfo?.az_sal2}
                                    onChange={fromYear2Change}
                                    options={years}
                                />
                            </Col>
                            <Col lg={12}>
                                <Select
                                    size={'large'}
                                    placeholder={'تا سال'}
                                    value={resInfo?.ta_sal2}
                                    onChange={toYear2Change}
                                    options={[{label:'هنوز اقامت دارم', value:'1111'},...years]}
                                />
                            </Col></>}
                    </Row>
                    <div style={{textAlign: 'left', margin: '1rem 0 0'}}>
                        <CustomButton text={'افزودن سفر جدید'} ghost={true} width={150} onClick={addInfo}/>
                    </div>
                    {info?.foreign_travel?.length > 0 && <div
                        id="scrollableDiv"
                        style={{
                            direction: 'ltr',
                            minHeight: 'fit-content',
                            maxHeight: 150,
                            margin: '1.5rem 0 0',
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                        <List
                            dataSource={info?.foreign_travel}
                            bordered={false}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className={'list-item-wrapper'}>
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                                <div>{getCountryFromCode(item.country_code)}</div>
                                                <div id={'delete'} onClick={() => deleteListItemHandler(item.id)}>
                                                    <DeleteOutlined className={'delete-icon'}/></div>
                                            </div>

                                        <div className={'delete-date-container'}>
                                            <div><span style={{color: '#818181'}}>ویزا: </span><span style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: '#656565'
                                            }}>{getVisaType(item.type_visa)}</span></div>
                                            {item.akharin_bar ?
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div>{getFullYear(item.akharin_bar)}</div>
                                                </div>
                                                :
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{getFullYear(item.az_sal)}</div>
                                                <span style={{margin: '0 5px'}}>-</span>
                                                <div>{getFullYear(item.ta_sal)}</div>
                                            </div>}
                                        </div>

                                        {item.type_visa2 && <div className={'delete-date-container'}>
                                            <div><span style={{color: '#818181'}}>ویزا: </span><span style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: '#656565'
                                            }}>{getVisaType(item.type_visa2)}</span></div>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{getFullYear(item.az_sal2)}</div>
                                                <span style={{margin: '0 5px'}}>-</span>
                                                <div>{getFullYear(item.ta_sal2)}</div>
                                            </div>
                                        </div>}
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>}
                </> :
                <Radio.Group onChange={onChange} value={value}>
                    <div><CustomRadioButton isChecked={value === 'yes'} value={'yes'} text={'بله'} width={300}/></div>
                    <div><CustomRadioButton isChecked={value === 'no'} value={'no'}
                                            text={'نه متاسفانه!!'} width={300}/></div>
                </Radio.Group>
            }
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default SixteenthStep