import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, Radio, Row} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const TwentiethStep = ({question}) =>{

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/21')
        }else{
            navigate('/questions/21')
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/19')
        } else {
            navigate('/questions/19')
        }
    }


    const onChange = (e) => {
        setInfo({...info,take_your_family_with_you: e.target.value})
    };

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={info.take_your_family_with_you}>
                <Row gutter={[12,12]}>
                <Col lg={24}><CustomRadioButton value={'1'}
                                        text={'نه من اصلا شرایط زندگیم طوری نیست که بتونم تنهاشون بذارم یا حتی خودم تنها اقدام کنم'}
                                         /></Col>
                <Col lg={24}><CustomRadioButton value={'2'}
                                        text={'نمیدونم خب سخته ولی برای گرفتن ویزا هرکاری به نفعم باشه و پیشنهاد بدی انجام میدم'}
                                         /></Col>
                </Row>
            </Radio.Group>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default TwentiethStep