import {Checkbox, Collapse,message} from 'antd';
import './BuyService.scss'
import CustomButton from "../general/CustomButton";
import Bcpnp from '../../images/bc-pnp-icon.svg'
import ExpressEntry from '../../images/express-entry-icon.svg'
import Info from '../../images/info.svg'
import {useLocation, useOutletContext} from "react-router";
import request from "../../utils/request";
import {StoreInformationUrl} from "../../utils/constants";
import {useState} from "react";


const {Panel} = Collapse;
const iconList =[Bcpnp,ExpressEntry]

const BuyService = () => {

    const [loading,setLoading]=useState(false)

    const location = useLocation();
    console.log('loc',location)

    const handleSubmit = ()=>{
        setLoading(true)
        request.post(StoreInformationUrl,JSON.parse(localStorage.getItem('info'))).then(res=>{
            setLoading(false)
            // setResult(res.data.data)
            // navigate('/buy-service',{state:res.data.data})
            message.success('اطلاعات با موفقیت ثبت شد')
            localStorage.removeItem('info')
        }).catch(e=>{
            setLoading(false)
            message.error('ثبت اطلاعات با خطا مواجه شد')
            localStorage.removeItem('info')
        })
            .finally(()=>{
                setLoading(false)
            })
    }

    return (
        <div className={'buy-service-container'}>
            <h2 className={'buy-service-title'}>{location?.state?.result_text?.title}</h2>
            <p style={{fontSize: '16px', color: '#777777', fontWeight: '400'}}>
                {location?.state?.result_text?.msg1}
            </p>
            {location?.state?.result_text?.suggestions.map((item,index)=>{
                return <div key={index}> <img src={iconList[index]} alt={'bc-pnp-icon'}/> {item}</div>
            })}
            {/*<Collapse accordion bordered={false}>*/}
            {/*    <Panel*/}
            {/*        header={<div> <img src={Bcpnp} alt={'bc-pnp-icon'}/> مسیر استانی بریتیش کلمبیا که بهش BC-PNP هم میگن</div>}*/}
            {/*        key="1"*/}
            {/*    >*/}
            {/*        <p>*/}
            {/*            مسیر BC-PNP در حقیقت همون تاییدیه استان بریتیش کلمبیاست که به شما اجازه میده به این استان مهاجرت*/}
            {/*            کنید و اونجا کار و اقامت داشته باشید. برای گرفتن امتیاز کافی این مسیر باید یه جاب آفر و یا همون*/}
            {/*            پیشنهاد شغلی از طرف یه کارفرما تو این استان داشته باشید که البته کارفرما یه سری شرایط خاصی باید*/}
            {/*            داشته باشه. بنابراین حتی اگه بدون داشتن این جاب آفر، امتیاز کافی برای مهاجرت به استان بریتیش*/}
            {/*            کلمبیا رو هم به دست بیارید، پرونده تون تکمیل نمیشه چون این آیتم جزو الزامات این مسیر مهاجرتی*/}
            {/*            حساب میشه.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            طبق اطلاعاتی که به ما دادی، ما یه محاسبه کردیم و معلوم شد که امتیاز شما از حداقل امتیاز لازم*/}
            {/*            برای گرفتن تاییدیه از این استان بالاتره و بنابراین ما عقیده داریم که شما واجد شرایط اقدام از این*/}
            {/*            مسیر هستی و درصورتی که یه جاب آفر رسمی داشته باشی میتونی اقامت دائم رو به محض ورود از مرز کانادا*/}
            {/*            (در فرودگاه) / بعد از **** کار کردن در ***** دریافت کنی.*/}
            {/*        </p>*/}
            {/*    </Panel>*/}
            {/*    <Panel*/}
            {/*        header={<div><img src={ExpressEntry} alt={'express-entry-icon'} />مسیر ورود سریع یا همون اکسپرس انتری که یه برنامه مهاجرتی دولت فدرال کاناداست</div>}*/}
            {/*        key="2"*/}
            {/*    >*/}
            {/*        <p>*/}
            {/*            مسیر Express Entry در حقیقت هممون مسیر معروف اصلی و قدیمی تر کانادا است که از سال 2015 تا الان*/}
            {/*            اصلی ترین مسیر برای جذب مهاجر طبق قوانین مهاجرت محسوب میشه که تعداد ظرفیت ها و شرایطش رو هر سال*/}
            {/*            تو مجلس کانادا تصویب می کنن. تو این مسیر، اگه امتیاز قابل قبولی کسب کنی، حتی اگه جاب آفریا همون*/}
            {/*            پیشنهاد شغلی از یه کارفرما تو کانادا رو هم نداشته باشی، همچنان می تونی مهاجرت کنی. طبق اطلاعاتی*/}
            {/*            که به ما دادی، ما یه محاسبه کردیم و معلوم شد که امتیاز شما از حداقل امتیاز لازم برای مهاجرت به*/}
            {/*            کانادا از طریق این مسیر بالاتره و بنابراین ما عقیده داریم که شما واجد شرایط اقدام از این مسیر*/}
            {/*            هستی.*/}
            {/*        </p>*/}
            {/*    </Panel>*/}
            {/*</Collapse>*/}
            <p style={{fontSize: '16px', color: '#777777', fontWeight: '400', marginTop: '1.5rem'}}>
                حالا اگه علاقمند هستی که در مورد مسیرهایی (مسیری) که گفتم بیشتر بدونی، یکی از سرویسهای مشاوره ای ما رو
                انتخاب کن تا همه جزئیات شون رو به طور کامل بهت توضیح بدم.
            </p>
            <p style={{fontSize: '16px', color: '#777777', fontWeight: '400', marginTop: '1.5rem'}}>
                <img src={Info} alt={'info-icon'} style={{marginLeft:'10px'}} />
                ضمنا بعضی وقتها اضافه کردن همسرت به پرونده، میتونه تو محاسبه امتیازها به نفعت باشه. به خاطر همین ما
                پیشنهاد می کنیم، حتی اگه قصدشو نداری که همزمان همسرت هم باهات مهاجرت کنه، اطلاعاتش رو تکمیل کن تا ما اگه
                گزینه های بهتری هم به ذهنمون رسید بهت بگیم.
            </p>
            <div style={{textAlign: 'right', marginTop: '1rem', fontSize: '18px', color: '#7E7E7E'}}><Checkbox>بررسی
                کامل پرونده برای مسیر استانی بریتیش کلمبیا</Checkbox></div>
            <div style={{textAlign: 'right', marginTop: '1rem'}}><Checkbox>بررسی کامل پرونده برای مسیر اکسپرس
                انتری</Checkbox></div>
            <div style={{textAlign: 'right', marginTop: '1rem'}}><Checkbox>اضافه کردن همسر به پرونده</Checkbox></div>
            <div style={{width: '100%', textAlign: "left"}}><CustomButton loading={loading} onClick={handleSubmit} text={'خرید سرویس'} width={150}
                                                                          style={{marginTop: '1rem'}}/>
            </div>
        </div>
    )
}

export default BuyService