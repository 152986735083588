import {Button} from "antd";
import './customButton.scss'

const CustomButton = ({type='primary',ghost,text,width='150px',style,onClick,htmlType='button',...props})=>{

    return (
        <Button
            type={type}
            ghost={ghost}
            className={'custom-button'}
            style={{...style, width: `${width}px`}}
            onClick={onClick}
            size={'large'}
            htmlType={htmlType}
            {...props}
        >
            {text}
        </Button>
    )
}

export default CustomButton