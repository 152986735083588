export const InitUrl = '/init'
export const StoreInformationUrl = '/store/job/offer'

export const years = [
    {label:'(2023) 1402 ',value:'2023'},
    {label:'(2022) 1401 ',value:'2022'},
    {label:'(2021) 1400 ',value:'2021'},
    {label:'(2020) 1399 ',value:'2020'},
    {label:'(2019) 1398 ',value:'2019'},
    {label:'(2018) 1397 ',value:'2018'},
    {label:'(2017) 1396 ',value:'2017'},
    {label:'(2016) 1395 ',value:'2016'},
    {label:'(2015) 1394 ',value:'2015'},
    {label:'(2014) 1393 ',value:'2014'},
    {label:'(2013) 1392 ',value:'2013'},
    {label:'(2012) 1391 ',value:'2012'},
    {label:'(2011) 1390 ',value:'2011'},
    {label:'(2010) 1389 ',value:'2010'},
    {label:'(2009) 1388 ',value:'2009'},
    {label:'(2008) 1387 ',value:'2008'},
    {label:'(2007) 1386 ',value:'2007'},
    {label:'(2006) 1385 ',value:'2006'},
    {label:'(2005) 1384 ',value:'2005'},
    {label:'(2004) 1383 ',value:'2004'},
    {label:'(2003) 1382 ',value:'2003'},
    {label:'(2002) 1381 ',value:'2002'},
    {label:'(2001) 1380 ',value:'2001'},
    {label:'(2000) 1379 ',value:'2000'},
]


export const childrenAges = ()=>{
    let ages = []
    for (let i=1; i<=30;i++) {
        ages.push({label:`${i}`,value:`${i}`})
    }
    return ages
}
