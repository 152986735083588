import {Radio, Select, Checkbox, Row, Col, List, Modal} from 'antd';
import CustomRadioButton from "../../general/CustomRadioButton";
import {useCallback, useState} from "react";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import {childrenAges} from "../../../utils/constants";

const NineteenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [showResumeModal, setShowResumeModal] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [children, setChildren] = useState(null)
    const [resInfo, setResInfo] = useState(null )
    const [childrenNumber, setChildrenNumber] = useState(null)
    const {info, setInfo, hasChild, setHasChild} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        setInfo({...info,having_a_child:resInfo})
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/20')
        }else{
            navigate('/questions/20')
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/18')
        } else {
            navigate('/questions/18')
        }
    }

    const handleChange = (value) => {
        setValue(true)
        setChildrenNumber(value)
        let childrenList = []
        for (let i = 0; i < value; i++) {
            childrenList.push({
                id: i,
                age: null,
                number: `${i === 0 ? 'اول' : i === 1 ? 'دوم' : i === 2 ? 'سوم' : i === 3 ? 'چهارم' : 'پنجم'}`
            })
        }
        setChildren(childrenList)
    };

    const onChangeHavingChild = (e) => {
        setHasChild(e.target.value);
        if (!e.target.value){
            setChildren(null)
            setChildrenNumber(null)
            setInfo({...info,having_a_child: null})
        }
    };

    const memoizedAges = useCallback(()=>{
        return childrenAges()
    },[])

    const handleOk = () => {
        setShowResumeModal(false);
    };
    const handleCancel = () => {
        setShowResumeModal(false);
        setIsChecked(false)
    };

    const handleChildrenNumberChange = (value)=>{
        setChildrenNumber(value)
        setResInfo(null)
        setInfo({...info,having_a_child: null})
        let childrenList = []
        for (let i = 0; i < value; i++) {
            childrenList.push({
                id: i,
                age: null,
                number: `${i === 0 ? 'اول' : i === 1 ? 'دوم' : i === 2 ? 'سوم' : i === 3 ? 'چهارم' : 'پنجم'}`
            })
        }
        setChildren(childrenList)
    }

    const handleAgeChange = (value,id)=>{
        if (resInfo) {
            setResInfo([...resInfo,{uid:id,child_age: value}])
        }else {
            setResInfo([{uid:id,child_age: value}])
        }
    }

    const handleUpdateAgeChange = (value,index)=>{
        let newItems = info.having_a_child.map(item=>{
           if (item.uid === index) {
               item.child_age = value
               return item
           }else {
               return item
           }
        })
        console.log('new',newItems)
        setInfo({...info,having_a_child: newItems})
    }

    const noChildrenChange = ()=>{
        setChildrenNumber(null)
        setChildren(null)
        setInfo({...info,having_a_child: null})
    }

    return (
        <div>
            <Modal open={showResumeModal} footer={false}>
                <div style={{textAlign: 'center', marginTop: '1rem'}}>
                    <h4>سابقه کار خیلی میتونه کمک کنه،</h4>
                    <h4>مطمئنی سابقه کار نداری؟</h4>
                    <div style={{margin: '2rem  0 1rem'}}>
                        <CustomButton onClick={handleOk} text={'بله'} ghost={true} width={100}/>
                        <CustomButton onClick={handleCancel} text={'خیر'} width={100} style={{marginRight: '15px'}}/>
                    </div>
                </div>
            </Modal>
            <div>{question}</div>
            <Radio.Group onChange={onChangeHavingChild} value={hasChild} style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{marginBottom: '1rem'}}><CustomRadioButton isChecked={hasChild} value={true} text={'بله'}
                                                                       width={150}/></div>

                <Row gutter={[12, 12]}>
                    <Col lg={24}>
                        <Select
                            size={'large'}
                            placeholder={'تعداد فرزند'}
                            value={childrenNumber || info?.having_a_child?.length}
                            onChange={handleChildrenNumberChange}
                            options={[
                                {
                                    value: 1,
                                    label: '1 فرزند',
                                },
                                {
                                    value: 2,
                                    label: '2 فرزند',
                                },
                                {
                                    value: 3,
                                    label: '3 فرزند',
                                },
                                {
                                    value: 4,
                                    label: '4 فرزند',
                                },
                                {
                                    value: 5,
                                    label: '5 فرزند',
                                },
                            ]}
                        />
                    </Col>
                    {
                        info?.having_a_child ?
                            info?.having_a_child?.map((item,index)=>{
                                return <Col key={index} lg={12}>
                                    <Select
                                        size={'large'}
                                        value={item?.child_age}
                                        // placeholder={` سن فرزند${' ' + index}`}
                                        onChange={(e)=>handleUpdateAgeChange(e,index)}
                                        options={memoizedAges()}
                                    />
                                </Col>
                            }) :
                        children?.map(item => {
                            return <Col key={item.id} lg={12}>
                                <Select
                                    size={'large'}
                                    placeholder={` سن فرزند${' ' + item?.number}`}
                                    onChange={(e)=>handleAgeChange(e,item.id)}
                                    options={memoizedAges()}
                                />
                            </Col>
                        })
                    }
                </Row>

                <div><CustomRadioButton onChange={noChildrenChange} isChecked={!hasChild} value={false} text={'نه بچه ندارم'} width={150}/></div>
            </Radio.Group>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default NineteenthStep