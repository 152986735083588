import {Radio, Select, Checkbox, Row, Col, List, Modal} from 'antd';
import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import CustomButton from "../../general/CustomButton";
import './tenthStep.scss'
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import {years} from "../../../utils/constants";

const {Option} = Select

const TenthStep = ({question}) => {

    const [value, setValue] = useState('');
    const [showResumeModal, setShowResumeModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [jobInfo, setJobInfo] = useState({code_reshte: null, az_sal: null, ta_sal: null, type: null})
    const [totalJobs, setTotalJobs] = useState([])


    const {info, setInfo, data} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if(info.work_experience.length>0){
            if (location.pathname.includes('partner')) {
                navigate('/questions/partner/11')
            } else {
                navigate('/questions/11')
            }
        }else {
            setShowModal(true)
        }

    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/9')
        } else {
            navigate('/questions/9')
        }
    }

    const onChangeCheckbox = (e) => {
        setIsChecked(true)
        if (e.target.checked) {
            setShowResumeModal(true)
        }
    }

    const deleteListItemHandler = (value) => {
        let items = info.work_experience.filter(item => item.code_reshte !== value)
        setInfo({...info, work_experience: items})
    }

    const handleOk = () => {
        setShowResumeModal(false);
        setJobInfo({code_reshte: null, az_sal: null, ta_sal: null, type: null})
        setInfo({...info, work_experience: null})
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/11')
        } else {
            navigate('/questions/11')
        }
    };
    const handleCancel = () => {
        setShowResumeModal(false);
        setShowModal(false)
        setIsChecked(false)
    };


    const fromYearChange = (value) => {
        setJobInfo({...jobInfo, az_sal: value})
    }
    const toYearChange = (value) => {
        setJobInfo({...jobInfo, ta_sal: value})
    }
    const handleJobChange = (value) => {
        setJobInfo({...jobInfo, code_reshte: value})
    }
    const jobTypeChange = (e) => {
        setJobInfo({...jobInfo, type: e.target.value})
    };
    const addJobInfo = () => {
        setTotalJobs([...totalJobs, {...jobInfo}])
        setInfo({...info, work_experience: [...info.work_experience, {...jobInfo}]})
        setJobInfo({code_reshte: null, az_sal: null, ta_sal: null, type: null})
    }
    const getTitleFromCode = (value) => {
        return data?.job_majors.find(item => item.code === value).persian_title
    }
    const getFullYear = (value) => {
        return years.find(item => item.label.includes(value)).label
    }

    return (
        <div>
            <Modal title={null} onCancel={handleCancel} open={showModal} footer={false}>
                <h3 style={{marginTop:'1rem'}}> لطفا اطلاعات رو به صورت کامل پر کن و در نهایت روی دکمه افرودن سابقه جدید بزن</h3>
                <div style={{width: '100%', textAlign: "center"}}><CustomButton  text={'متوجه شدم'}
                                                                                 width={150}
                                                                                 style={{marginTop: '1rem'}}
                                                                                 onClick={handleCancel}
                />
                </div>
            </Modal>
            <Modal open={showResumeModal} footer={false}>
                <div style={{textAlign: 'center', marginTop: '1rem'}}>
                    <h4>سابقه کار خیلی میتونه کمک کنه،</h4>
                    <h4>مطمئنی سابقه کار نداری؟</h4>
                    <div style={{margin: '2rem  0 1rem'}}>
                        <CustomButton onClick={handleOk} text={'بله'} ghost={true} width={100}/>
                        <CustomButton onClick={handleCancel} text={'خیر'} width={100} style={{marginRight: '15px'}}/>
                    </div>
                </div>
            </Modal>
            <div>{question}</div>
            <Row gutter={[12, 12]}>
                <Col lg={12}>
                    <Select
                        size={'large'}
                        placeholder={'از سال'}
                        value={jobInfo.az_sal}
                        // defaultValue={5}
                        onChange={fromYearChange}
                        options={years}
                    />
                </Col>
                <Col lg={12}>
                    <Select
                        size={'large'}
                        placeholder={'تا سال'}
                        value={jobInfo.ta_sal}
                        // defaultValue={6}
                        onChange={toYearChange}
                        options={years}
                    />
                </Col>
                <Col lg={24}>
                    <Select
                        size={'large'}
                        placeholder={'زمینه شغلی '}
                        value={jobInfo.code_reshte}
                        // defaultValue={''}
                        onChange={handleJobChange}
                    >
                        {data?.job_majors?.map(item => {
                            return <Option key={item.id} value={item.code}>{item.persian_title}</Option>
                        })
                        }
                    </Select>
                </Col>
            </Row>
            <Radio.Group onChange={jobTypeChange} value={jobInfo.type} style={{display: 'flex'}}>
                <div><CustomRadioButton isChecked={jobInfo.type === 'fulltime'} value={'fulltime'} text={'تمام وقت'}
                                        width={150}/></div>
                <div style={{marginRight: '1rem'}}><CustomRadioButton isChecked={jobInfo.type === 'parttime'}
                                                                      value={'parttime'}
                                                                      text={'نیمه وقت'} width={150}/></div>
            </Radio.Group>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div>
                    <CustomButton ghost={true} width={150} text={'افزودن سابقه جدید'} onClick={addJobInfo}/>
                </div>
                <div style={{textAlign: 'right', marginTop: '1rem'}}><Checkbox checked={isChecked}
                    // disabled={data.length > 0}
                                                                               onChange={onChangeCheckbox}>سابقه کار
                    ندارم</Checkbox></div>
            </div>

            {info.work_experience?.length > 0 && <div
                id="scrollableDiv"
                style={{
                    height: 100,
                    marginTop: '1rem',
                    overflow: 'auto',
                    padding: '0 16px',
                    // border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
            >
                <List
                    dataSource={info.work_experience}
                    bordered={false}
                    renderItem={(item) => (
                        <List.Item>
                            <div className={'list-item-wrapper'}>
                                <div>{getTitleFromCode(item.code_reshte)}{" " + `(${item.type === 'fulltime' ? 'تمام وقت' : 'نیمه وقت'})`}</div>
                                <div className={'delete-date-container'}>
                                    <div>{getFullYear(item.az_sal) + " - " + getFullYear(item.ta_sal)}</div>
                                    <div id={'delete'} onClick={() => deleteListItemHandler(item.code_reshte)}>
                                        <DeleteOutlined
                                            className={'delete-icon'}/></div>
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            </div>}
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default TenthStep