import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, List, Modal, Radio, Row, Select} from "antd";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import './SeventeenthStep.scss'
import {useLocation, useNavigate, useOutletContext} from "react-router";
import {v4 as uuidv4} from "uuid";

const SeventeenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [showQuestion, setShowQuestion] = useState(false);
    const [resInfo, setResInfo] = useState({ family_type: null , vaziat_eghamat: null , ostan_mahal_eghamat: null  , saheb_business: null } )
    const [showModal, setShowModal] = useState(false);

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/18')
        }else{
            navigate('/questions/18')
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/16')
        } else {
            navigate('/questions/16')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'no') {
            setInfo({...info,family_in_canada: null})
        } else {
            setShowQuestion(true)
        }
    };

    const addInfo = () => {
        info?.family_in_canada ?
            setInfo({...info, family_in_canada: [...info?.family_in_canada, {id: uuidv4(), ...resInfo}]}) :
            setInfo({...info, family_in_canada: [{id: uuidv4(), ...resInfo}]})
        setResInfo({ family_type: null , vaziat_eghamat: null , ostan_mahal_eghamat: null  , saheb_business: null })
    };

    const handleCancel = () => {
        setShowModal(false);
        setValue(null)
    };

    const relationHandleChange = (value)=>{
        setResInfo({...resInfo,family_type: value})
    }

    const eghamatHandleChange = (value)=>{
        setResInfo({...resInfo,vaziat_eghamat: value})
    }

    const ostanHandleChange = (value)=>{
        setResInfo({...resInfo,ostan_mahal_eghamat: value})
    }

    const businessHandleChange = (value)=>{
        setResInfo({...resInfo,saheb_business: value})
    }

    const deleteListItemHandler = (id) => {
        let items = info?.family_in_canada?.filter(item => item.id !== id)
        setInfo({...info, family_in_canada: items?.length > 0 ? items : null})
    }

    const getFamilyType = (value) => {
        if (value==='1') return 'پدر، مادر، خواهر، برادر'
        if (value==='2') return 'فامیل درجه دو'
        if (value==='3') return 'فامیل دور'
        if (value==='4') return 'دوست'
    }

    const getEghamatStatus = (value) => {
        if (value==='1') return 'اقامت موقت'
        if (value==='2') return 'اقامت دائم'
        if (value==='3') return 'سیتیزن'
        if (value==='4') return 'پناهنده'
        if (value==='5') return 'نمیدونم'
    }

    const getOstanName = (value) => {
        if (value==='1') return 'نمیدونم'
        if (value==='2') return 'BC'
    }

    const getBusinessStatus = (value) => {
        if (value==='1') return 'بله'
        if (value==='2') return 'خیر'
    }

    return (
        <div style={{textAlign: 'right'}}>
            <div>{question}</div>
            {showQuestion || info?.family_in_canada ?
                <>
                    <div style={{fontSize: '12.5px', color: '#686868', marginBottom: '1rem'}}>لطفا اطلاعات زیر را کامل
                        کن
                    </div>
                    <Row gutter={[12, 12]}>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'نوع رابطه'}
                                value={resInfo?.family_type}
                                onChange={relationHandleChange}
                                options={[
                                    {
                                        value: '1',
                                        label: 'پدر، مادر، خواهر، برادر',
                                    },
                                    {
                                        value: '2',
                                        label: 'فامیل درجه دو',
                                    },
                                    {
                                        value: '3',
                                        label: 'فامیل دور',
                                    },
                                    {
                                        value: '4',
                                        label: 'دوست',
                                    }
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'وضعیت اقامت'}
                                value={resInfo?.vaziat_eghamat}
                                onChange={eghamatHandleChange}
                                options={[
                                    {
                                        value: '1',
                                        label: 'اقامت موقت',
                                    },
                                    {
                                        value: '2',
                                        label: 'اقامت دائم',
                                    },
                                    {
                                        value: '3',
                                        label: 'سیتیزن',
                                    },
                                    {
                                        value: '4',
                                        label: 'پناهنده',
                                    },
                                    {
                                        value: '5',
                                        label: 'نمیدونم',
                                    }
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'استان محل اقامت'}
                                value={resInfo?.ostan_mahal_eghamat}
                                onChange={ostanHandleChange}
                                options={[
                                    {
                                        value: '1',
                                        label: 'نمیدونم',
                                    },
                                    {
                                        value: '2',
                                        label: 'BC',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'مالکیت بیزنس در کانادا'}
                                value={resInfo?.saheb_business}
                                onChange={businessHandleChange}
                                options={[
                                    {
                                        value: '1',
                                        label: 'بله',
                                    },
                                    {
                                        value: '2',
                                        label: 'خیر',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <div style={{textAlign: 'left', margin: '1rem 0 2rem'}}>
                        <CustomButton text={'افزودن فرد جدید'} ghost={true} width={150} onClick={addInfo}/>
                    </div>
                    {/*<div className={'person-card-wrapper'}>*/}
                    <Row gutter={[12,12]} style={{margin:'1rem 0'}}>
                        {
                            info?.family_in_canada?.map(item => {
                                return <Col key={item.id} lg={12}> <div className={'family-card'}>
                                    <div className={'family-card-title-container'}>
                                        <div className={'family-title'}>{getFamilyType(item?.family_type)}</div>
                                        <div id={'delete'} onClick={() => deleteListItemHandler(item.id)}>
                                            <DeleteOutlined className={'delete-icon'}/></div>
                                    </div>
                                    <div style={{marginTop:'10px'}}>وضعیت: <span style={{fontSize:'15px',fontWeight:'bold'}}>{getEghamatStatus(item?.vaziat_eghamat)}</span></div>
                                    <div style={{marginTop:'10px'}}>محل اقامت: <span style={{fontSize:'15px',fontWeight:'bold'}}>{getOstanName(item?.ostan_mahal_eghamat)}</span></div>
                                    <div style={{marginTop:'10px'}}>مالکیت بیزنس: <span style={{fontSize:'15px',fontWeight:'bold'}}>{getBusinessStatus(item?.saheb_business)}</span></div>
                                </div>
                                </Col>
                            })
                        }
                    </Row>
                    {/*</div>*/}
                </>
                :
                <Radio.Group onChange={onChange} value={value}>
                    <div><CustomRadioButton isChecked={value === 'yes'} value={'yes'} text={'بله'} width={300}/></div>
                    <div><CustomRadioButton isChecked={value === 'no'} value={'no'}
                                            text={'نه متاسفانه کسی رو در کانادا ندارم!'} width={300}/></div>
                </Radio.Group>

            }
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default SeventeenthStep