import CustomRadioButton from "../../general/CustomRadioButton";
import {useEffect, useState} from "react";
import {Radio} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const FifthStep = ({question}) =>{

    const [value, setValue] = useState('');
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/6')
        }else{
            navigate('/questions/6')
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/4')
        } else {
            navigate('/questions/4')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
        setInfo({...info,first_language:e.target.value})
    };

    useEffect(()=>{
        if (value || info.first_language) {
            document.addEventListener('keypress',(e)=>{
                if (e.key === "Enter") {
                    if (location.pathname.includes('partner')) {
                        navigate('/questions/partner/6')
                    } else {
                        navigate('/questions/6')
                    }
                }
            })
        }

        return ()=>document.removeEventListener('keypress',(e)=>{
            if (e.key === "Enter") {
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/6')
                } else {
                    navigate('/questions/6')
                }
            }
        })
    },[])

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={info.first_language}>
            <div><CustomRadioButton isChecked={info.first_language==='english'} value={'english'} text={'انگلیسی'} width={150} /></div>
            <div><CustomRadioButton disabled={true} isChecked={value==='french'} value={'french'} text={'فرانسه'} width={150} /></div>
            </Radio.Group>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default FifthStep