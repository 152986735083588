import {Col, Input, Modal, Row, Select} from 'antd';
import CustomButton from "../../general/CustomButton";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import {useState} from "react";
import { years} from "../../../utils/constants";

const {Option} = Select

const NinthStep = ({question}) => {

    const [showModal, setShowModal] = useState(false)
    const {
        info,
        setInfo,
        data,
        lastDegree,
        setLastDegree,
        doctorate,
        setDoctorate,
        master,
        setMaster,
        bachelor,
        setBachelor,
        associate,
        setAssociate,
        secondary,
        setSecondary,
        primary,
        setPrimary
    } = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()


    const nextPageHandler = () => {
        if (lastDegree===6){
            if (!doctorate.education_level || !doctorate.code_reshte || !doctorate.year_of_graduation
                || !master.education_level || !master.code_reshte || !master.year_of_graduation
                || !bachelor.education_level || !bachelor.code_reshte || !bachelor.year_of_graduation) {
                setShowModal(true)
            }else{
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/10')
                } else {
                    navigate('/questions/10')
                }
            }
        }
        else if (lastDegree===5){
            if (!master.education_level || !master.code_reshte || !master.year_of_graduation
                || !bachelor.education_level || !bachelor.code_reshte || !bachelor.year_of_graduation
                || !associate.education_level || !associate.code_reshte || !associate.year_of_graduation
            ) {
                setShowModal(true)
            }else{
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/10')
                } else {
                    navigate('/questions/10')
                }
            }
        }
        else if (lastDegree===4){
            if (!bachelor.education_level || !bachelor.code_reshte || !bachelor.year_of_graduation) {
                setShowModal(true)
            }else{
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/10')
                } else {
                    navigate('/questions/10')
                }
            }
        }
        else if (lastDegree===3){
            if (!associate.education_level || !associate.code_reshte || !associate.year_of_graduation) {
                setShowModal(true)
            }else{
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/10')
                } else {
                    navigate('/questions/10')
                }
            }
        }
        else if (lastDegree===2){
            if (!secondary.education_level || !secondary.code_reshte || !secondary.year_of_graduation) {
                setShowModal(true)
            }else{
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/10')
                } else {
                    navigate('/questions/10')
                }
            }
        }
        else if (lastDegree===1){
            if (!primary.education_level || !primary.code_reshte || !primary.year_of_graduation) {
                setShowModal(true)
            }else{
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/10')
                } else {
                    navigate('/questions/10')
                }
            }
        }else{
            setShowModal(true)
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/8')
        } else {
            navigate('/questions/8')
        }
    }


    const changeDegreeHandler = (value) => {
        setLastDegree(value)
        if (value===1){
            setPrimary({...primary,degree:1})
        }
        if (value===2){
            setSecondary({...secondary,degree:2})
        }
        if (value===3){
            setAssociate({...associate,degree:3})
        }
        if (value===4){
            setBachelor({...bachelor,degree:4})
        }
        if (value===5){
            setMaster({...master,degree:5})
        }
        if (value===6){
            setDoctorate({...doctorate,degree:6})
        }
    };

    const checkDegreeValue = ()=>{
        if(lastDegree===1){
            return primary.degree
        }
        if(lastDegree===2){
            return secondary.degree
        }
        if(lastDegree===3){
            return associate.degree
        }
        if(lastDegree===4){
            return bachelor.degree
        }
        if(lastDegree===5){
            return master.degree
        }
        if(lastDegree===6){
            return doctorate.degree
        }
        return null
    }

    const changeEducationHandler = (value) => {
        if (lastDegree === 1) {
            setPrimary({...primary, education_level: 1, code_reshte: value})
        }
        if (lastDegree === 2) {
            setSecondary({...secondary, education_level: 2, code_reshte: value})
        }
        if (lastDegree === 3) {
            setAssociate({...associate, education_level: 3, code_reshte: value})
        }
        if (lastDegree === 4) {
            setBachelor({...bachelor, education_level: 4, code_reshte: value})
        }
        if (lastDegree === 5) {
            setMaster({...master, education_level: 5, code_reshte: value})
        }
        if (lastDegree === 6) {
            setDoctorate({...doctorate, education_level: 6, code_reshte: value})
        }
    }

    const checkEducationValue = () => {
        if (lastDegree === 1 && primary.code_reshte) {
            return primary.code_reshte
        }
        if (lastDegree === 2 && secondary.code_reshte) {
            return secondary.code_reshte
        }
        if (lastDegree === 3 && associate.code_reshte) {
            return associate.code_reshte
        }
        if (lastDegree === 4 && bachelor.code_reshte) {
            return bachelor.code_reshte
        }
        if (lastDegree === 5 && master.code_reshte) {
            return master.code_reshte
        }
        if (lastDegree === 6 && doctorate.code_reshte) {
            return doctorate.code_reshte
        }
        return null
    }

    const changeYearHandler = (value) => {
        if (lastDegree === 1) {
            setPrimary({...primary, year_of_graduation: value})
            setInfo({
                ...info,
                highest_level_of_education: [...info.highest_level_of_education, {
                    ...primary,
                    year_of_graduation: value
                }]
            })
        }
        if (lastDegree === 2) {
            setSecondary({...secondary, year_of_graduation: value})
            setInfo({
                ...info,
                highest_level_of_education: [...info.highest_level_of_education, {
                    ...secondary,
                    year_of_graduation: value
                }]
            })
        }
        if (lastDegree === 3) {
            setAssociate({...associate, year_of_graduation: value})
            setInfo({
                ...info,
                highest_level_of_education: [...info.highest_level_of_education, {
                    ...associate,
                    year_of_graduation: value
                }]
            })
        }
        if (lastDegree === 4) {
            setBachelor({...bachelor, year_of_graduation: value})
            setInfo({
                ...info,
                highest_level_of_education: [...info.highest_level_of_education, {
                    ...bachelor,
                    year_of_graduation: value
                }]
            })
        }
        if (lastDegree === 5) {
            setMaster({...master, year_of_graduation: value})
            setInfo({
                ...info,
                highest_level_of_education: [...info.highest_level_of_education, {...master, year_of_graduation: value}]
            })
        }
        if (lastDegree === 6) {
            setDoctorate({...doctorate, year_of_graduation: value})
            setInfo({
                ...info,
                highest_level_of_education: [...info.highest_level_of_education, {
                    ...doctorate,
                    year_of_graduation: value
                }]
            })
        }
    }

    const checkYearValue = () => {
        if (lastDegree === 1 && primary.year_of_graduation) {
            return primary.year_of_graduation
        }
        if (lastDegree === 2 && secondary.year_of_graduation) {
            return secondary.year_of_graduation
        }
        if (lastDegree === 3 && associate.year_of_graduation) {
            return associate.year_of_graduation
        }
        if (lastDegree === 4 && bachelor.year_of_graduation) {
            return bachelor.year_of_graduation
        }
        if (lastDegree === 5 && master.year_of_graduation) {
            return master.year_of_graduation
        }
        if (lastDegree === 6 && doctorate.year_of_graduation) {
            return doctorate.year_of_graduation
        }
        return null
    }

    const changeMasterEducationHandler = (value) => {
        setMaster({...master, education_level: 5, code_reshte: value})
        // setInfo({...info,highest_level_of_education:[...info.highest_level_of_education,{...master,education_level:'4',code_reshte:value}]})
    }
    const changeMasterYearHandler = (value) => {
        setMaster({...master, education_level: 5, year_of_graduation: value})
        setInfo({
            ...info,
            highest_level_of_education: [...info.highest_level_of_education, {
                ...master,
                education_level: 5,
                year_of_graduation: value
            }]
        })
    }

    const changeBachelorEducationHandler = (value) => {
        setBachelor({...bachelor, education_level: 4, code_reshte: value})
        // setInfo({...info,highest_level_of_education:[...info.highest_level_of_education,{...bachelor,education_level:'3',code_reshte:value}]})
    }
    const changeBachelorYearHandler = (value) => {
        setBachelor({...bachelor, education_level: 4, year_of_graduation: value})
        setInfo({
            ...info,
            highest_level_of_education: [...info.highest_level_of_education, {
                ...bachelor,
                education_level: 4,
                year_of_graduation: value
            }]
        })
    }

    const changeAssociateEducationHandler = (value) => {
        setAssociate({...associate, education_level: 3, code_reshte: value})
        // setInfo({...info,highest_level_of_education:[...info.highest_level_of_education,{...associate,education_level:'2',code_reshte:value}]})
    }
    const changeAssociateYearHandler = (value) => {
        setAssociate({...associate, education_level: 3, year_of_graduation: value})
        setInfo({
            ...info,
            highest_level_of_education: [...info.highest_level_of_education, {
                ...associate,
                education_level: 3,
                year_of_graduation: value
            }]
        })
    }

    const getEducationMajors = (id) => {
        const item = data?.education_categories.find(item => item.id === id)
        return item?.educationMajors
    }

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Modal title={null} onCancel={handleCancel} open={showModal} footer={false}>
                <h3>لطفا اطلاعات رو به صورت کامل پر کن</h3>
                <div style={{width: '100%', textAlign: "center"}}><CustomButton  text={'متوجه شدم'}
                                                                               width={150}
                                                                               style={{marginTop: '1rem'}}
                                                                               onClick={handleCancel}
                />
                </div>
            </Modal>
            <div>{question}</div>

                <Row gutter={[12, 12]}>
                    <Col lg={8}>

                            <Select
                                value={checkDegreeValue()}
                                size={'large'}
                                placeholder={'آخرین مدرک تحصیلی'}
                                onChange={changeDegreeHandler}
                                // options={[
                                //     {
                                //         value: '1',
                                //         label: 'دیپلم',
                                //     },
                                //     {
                                //         value: '2',
                                //         label: 'فوق دیپلم',
                                //     },
                                //     {
                                //         value: '3',
                                //         label: 'لیسانس',
                                //     },
                                //     {
                                //         value: '4',
                                //         label: 'فوق لیسانس',
                                //     },
                                //     {
                                //         value: '5',
                                //         label: 'دکتری',
                                //     },
                                // ]}
                            >
                                {data?.education_categories?.map(item=>{
                                    return <Option key={item.id} value={item.id}>{item.persian_title}</Option>
                                })}
                            </Select>

                    </Col>
                    <Col lg={8}>

                        <Select
                            value={checkEducationValue()}
                            size={'large'}
                            placeholder={'رشته تحصیلی'}
                            onChange={changeEducationHandler}
                        >
                            {getEducationMajors(lastDegree)?.map(item => {
                                return <Option key={item.id} value={item.id}>{item.persian_title}</Option>
                            })
                            }
                        </Select>

                    </Col>
                    <Col lg={8}>

                        <Select
                            value={checkYearValue()}
                            onChange={changeYearHandler}
                            size={'large'}
                            placeholder={'سال فارغ التحصیلی '}
                            options={years}
                        />

                    </Col>

                    {lastDegree === 6 && <><Col lg={8}>
                        <Input name={'name'} size={'large'} defaultValue={'فوق لیسانس'} readOnly={true}
                               style={{fontSize: '14px'}}/>
                    </Col>
                        <Col lg={8}>

                            <Select
                                onChange={changeMasterEducationHandler}
                                value={master.code_reshte ? master.code_reshte : null}
                                size={'large'}
                                placeholder={'رشته تحصیلی'}
                            >
                                {getEducationMajors(5)?.map(item => {
                                    return <Option key={item.id} value={item.id}>{item.persian_title}</Option>
                                })
                                }
                            </Select>

                        </Col>
                        <Col lg={8}>

                            <Select
                                onChange={changeMasterYearHandler}
                                value={master.year_of_graduation ? master.year_of_graduation : null}
                                size={'large'}
                                placeholder={'سال فارغ التحصیلی '}
                                options={years}
                            />

                        </Col></>}


                    {(lastDegree === 6 || lastDegree === 5) && <><Col lg={8}>
                        <Input name={'name'} size={'large'} value={'لیسانس'} readOnly={true}
                               style={{fontSize: '14px'}}/>
                    </Col>
                        <Col lg={8}>

                            <Select
                                onChange={changeBachelorEducationHandler}
                                value={bachelor.code_reshte ? bachelor.code_reshte : null}
                                size={'large'}
                                placeholder={'رشته تحصیلی'}
                            >
                                {getEducationMajors(4)?.map(item => {
                                    return <Option key={item.id} value={item.id}>{item.persian_title}</Option>
                                })
                                }
                            </Select>

                        </Col>
                        <Col lg={8}>

                            <Select
                                onChange={changeBachelorYearHandler}
                                value={bachelor.year_of_graduation ? bachelor.year_of_graduation : null}
                                size={'large'}
                                placeholder={'سال فارغ التحصیلی '}
                                options={years}
                            />

                        </Col></>}

                    {(lastDegree === 4 || lastDegree === 5) && <><Col lg={8}>
                        <Input name={'name'} size={'large'} value={'فوق دیپلم'} readOnly={true}
                               style={{fontSize: '14px'}}/>
                    </Col>
                        <Col lg={8}>
                            <Select
                                onChange={changeAssociateEducationHandler}
                                value={associate.code_reshte ? associate.code_reshte : null}
                                size={'large'}
                                placeholder={'رشته تحصیلی'}
                            >
                                {getEducationMajors(3)?.map(item => {
                                    return <Option key={item.id} value={item.id}>{item.persian_title}</Option>
                                })
                                }
                            </Select>
                        </Col>
                        <Col lg={8}>
                            <Select
                                onChange={changeAssociateYearHandler}
                                value={associate.year_of_graduation ? associate.year_of_graduation : null}
                                size={'large'}
                                placeholder={'سال فارغ التحصیلی '}
                                options={years}
                            />
                        </Col></>}
                </Row>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default NinthStep