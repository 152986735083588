import {AutoComplete, Input, Select} from "antd";
import CountryList from 'country-list-with-dial-code-and-flag'
import {useLocation, useNavigate, useOutletContext, useParams} from "react-router";
import CustomButton from "../../general/CustomButton";
import {useEffect, useState} from "react";
import Flag from "react-world-flags";

const {Option} = Select

const ThirdStep = ({question}) => {

    const [selectedCode, setSelectedCode] = useState('98+')
    const [selectedName, setSelectedName] = useState('ir')
    const {info, setInfo,phone,setPhone} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()


    const nextPageHandler = () => {
        if (info.phone) {
            if (location.pathname.includes('partner')) {
                navigate('/questions/partner/4')
            } else {
                navigate('/questions/4')
            }
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/2')
        } else {
            navigate('/questions/2')
        }
    }

    const countriesList = CountryList.getAll()


    const selectCountryHandler = (data) => {
        setSelectedCode(data.split('_')[0])
        setSelectedName(data.split('_')[1].toLowerCase())
    }

    const keyPressHandler = (e) => {
        if (e.key === "Enter" && e.target.value) {
            setInfo({...info, phone: e.target.value})
            if (location.pathname.includes('partner')) {
                navigate('/questions/partner/4')
            } else {
                navigate('/questions/4')
            }
        }
    }


    const changeInfoHandler = (e) => {
        if (e.target.value.startsWith('0')) {
            setInfo({...info, phone: selectedCode + e.target.value.replace('0', '')})
            setPhone(e.target.value.replace('0', ''))
        } else {
            setInfo({...info, phone: selectedCode + e.target.value})
            setPhone(e.target.value)
        }
    }


    const flagRender = () => {
        // return <span style={{width:'32',height:'24px'}} className={`fi fis fi-${selectedCode}`} />
        return <Flag code={selectedName} style={{height: '24px', width: '32px', borderRadius: '5px'}}/>
    }


    return (
        <div>
            <div>{question}</div>
            <Input.Group compact style={{direction: 'ltr'}}>
                <Select
                    showSearch
                    defaultValue={selectedCode}
                    size={'large'}
                    style={{
                        width: '20%',
                        direction: 'ltr'
                    }}

                    suffixIcon={flagRender()}
                    onChange={selectCountryHandler}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {
                        countriesList.map((item, index) => {
                            return <Option key={index} value={item?.data?.dial_code + '_' + item?.data?.code}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    direction: 'rtl'
                                }}>
                                    <div dir={'ltr'}>{item?.data?.dial_code}</div>
                                    {/*<div>{item?.data?.code}</div>*/}
                                </div>
                            </Option>
                        })
                    }
                </Select>
                <Input placeholder={'شماره تلفن خود را وارد کنید'} name={'phoneNumber'} size={'large'}
                       onChange={changeInfoHandler}
                       onKeyPress={keyPressHandler}
                       value={phone}
                       style={{
                           width: '80%',
                       }}/>
            </Input.Group>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem',backgroundColor:`${info.phone ? '#32B8C5':'#ADF2F8'}`}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default ThirdStep