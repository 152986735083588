import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, List, Modal, Radio, Row, Select} from "antd";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import {years} from "../../../utils/constants";
import {v4 as uuidv4} from 'uuid';

const typeApply = [
    {
        value: 1,
        label: 'جاب آفر',
    },
    {
        value: 2,
        label: 'تحصیلی',
    },
    {
        value: 3,
        label: 'سرمایه گذاری',
    },
    {
        value: 4,
        label: 'توریستی',
    },
    {
        value: 5,
        label: 'اقامت دائم',
    },
    {
        value: 6,
        label: 'سایر',
    },
]

const result = [
    {
        value: 1,
        label: 'موفق',
    },
    {
        value: 2,
        label: 'ناموفق',
    },
    {
        value: 3,
        label: 'نامعلوم',
    },
    {
        value: 4,
        label: 'هنوز نتیجه اعلام نشده',
    },
]

const FourteenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [applyInfo, setApplyInfo] = useState({sal: null, type_apply: null, natije: null, sal2: null})

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/15')
        } else {
            navigate('/questions/15')
        }
    }

    const prevPageHandler = () => {
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/13')
        } else {
            navigate('/questions/13')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'no') {
            setShowModal(true)
        } else {
            setShowQuestion(true)
        }
    };

    const handleOk = () => {
        setShowModal(false);
        setValue('yes')
        setInfo({...info, apply_for_canada: null})
        nextPageHandler()
    };

    const handleCancel = () => {
        setShowModal(false);
        setValue(null)
    };

    const deleteListItemHandler = (id) => {
        // let items = data.filter(item => item.id !== id)
        // setData(items)
        let items = info?.apply_for_canada?.filter(item => item.id !== id)
        setInfo({...info, apply_for_canada: items?.length > 0 ? items : null})
    }

    const changeStatusHandler = (value) => {
        setApplyInfo({...applyInfo, natije: value})
    }

    const applyYearHandler = (value) => {
        setApplyInfo({...applyInfo, sal: value})
    }

    const expirationHandler = (value) => {
        setApplyInfo({...applyInfo, sal2: value})
    }

    const typeHandler = (value) => {
        setApplyInfo({...applyInfo, type_apply: value})
    }

    const addInfo = () => {
        if (applyInfo.sal && applyInfo.natije && applyInfo.natije !== 3 && applyInfo.type_apply && applyInfo.sal2) {
            info?.apply_for_canada ?
                setInfo({...info, apply_for_canada: [...info?.apply_for_canada, {id: uuidv4(), ...applyInfo}]}) :
                setInfo({...info, apply_for_canada: [{id: uuidv4(), ...applyInfo}]})
            setApplyInfo({sal: null, type_apply: null, natije: null, sal2: null})
        } else if (applyInfo.sal && applyInfo.natije === 3 && applyInfo.type_apply) {
            info?.apply_for_canada ?
                setInfo({...info, apply_for_canada: [...info?.apply_for_canada, {id: uuidv4(), ...applyInfo}]}) :
                setInfo({...info, apply_for_canada: [{id: uuidv4(), ...applyInfo}]})
            setApplyInfo({sal: null, type_apply: null, natije: null, sal2: null})
        }
    }

    const getTypeApplyFromCode = (value) => {
        return typeApply.find(item => item.value === value).label
    }
    const getResultFromCode = (value) => {
        return result.find(item => item.value === value).label
    }
    const getFullYear = (value) => {
        return years.find(item => item.label.includes(value)).label
    }


    return (
        <div style={{textAlign: 'right'}}>
            <Modal onCancel={handleCancel} open={showModal} footer={false}>
                <div style={{textAlign: "center"}}>
                    <div style={{fontSize: '16px', color: '#686868', marginBottom: '1rem', fontWeight: 'bold'}}>این سوال
                        خیلی مهمه
                    </div>
                    <div style={{fontSize: '16px', color: '#686868', marginBottom: '1rem', fontWeight: 'bold'}}>مطمئنی
                        تاحالا برای کانادا اقدام نکردی؟
                    </div>
                </div>
                <div style={{textAlign: 'center', margin: '2rem 0 1rem'}}>
                    <CustomButton text={'بله'} ghost={true} width={120} onClick={handleOk}/>
                    <CustomButton text={'خیر'} width={120} onClick={handleCancel} style={{marginRight: '1rem'}}/>
                </div>
            </Modal>
            <div>{question}</div>
            {showQuestion || info?.apply_for_canada ?
                <>
                    <div style={{fontSize: '12.5px', color: '#686868', marginBottom: '1rem'}}>لطفا اطلاعات زیر را کامل
                        کن
                    </div>
                    <Row gutter={[12, 12]}>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'سال'}
                                value={applyInfo?.sal}
                                onChange={applyYearHandler}
                                options={years}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'نوع پرونده'}
                                value={applyInfo?.type_apply}
                                onChange={typeHandler}
                                options={typeApply}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'وضعیت'}
                                value={applyInfo?.natije}
                                onChange={changeStatusHandler}
                                options={result}
                            />
                        </Col>
                        {applyInfo.natije === 1 && <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'تاریخ انقضا'}
                                value={applyInfo?.sal2}
                                onChange={expirationHandler}
                                options={years}
                            />
                        </Col>}
                        {applyInfo.natije === 2 && <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'سال اقدام'}
                                value={applyInfo?.sal2}
                                onChange={expirationHandler}
                                options={years}
                            />
                        </Col>}
                        {applyInfo.natije === 4 && <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'سال اقدام'}
                                value={applyInfo?.sal2}
                                onChange={expirationHandler}
                                options={years}
                            />
                        </Col>}
                    </Row>
                    <div style={{textAlign: 'left', margin: '2rem 0 1rem'}}>
                        <CustomButton text={'افزودن اقدام جدید'} ghost={true} width={140} onClick={addInfo}/>
                    </div>
                    {info?.apply_for_canada?.length > 0 && <div
                        id="scrollableDiv"
                        style={{
                            direction: 'ltr',
                            minHeight: 'fit-content',
                            maxHeight: 150,
                            margin: '2rem 0',
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                        <List
                            dataSource={info?.apply_for_canada}
                            bordered={false}
                            renderItem={(item) => (
                                <List.Item key={item.id}>
                                    <div className={'list-item-wrapper'}>
                                        <div>
                                            <div>{getTypeApplyFromCode(item.type_apply)}</div>
                                            <div><span style={{color: '#818181'}}>نتیجه: </span><span style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: '#656565'
                                            }}>{getResultFromCode(item.natije)}</span></div>
                                        </div>
                                        <div className={'delete-date-container'}>
                                            <div>
                                                <div>{getFullYear(item.sal)}</div>
                                                <div>{item.natije !== 3 ? getFullYear(item.sal2) : ''}</div>
                                            </div>
                                            <div id={'delete'} onClick={() => deleteListItemHandler(item.id)}>
                                                <DeleteOutlined className={'delete-icon'}/></div>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>}
                </> :
                <Radio.Group onChange={onChange} value={value}>
                    <div><CustomRadioButton value={'yes'} text={'بله'} width={300}/></div>
                    <div><CustomRadioButton value={'no'}
                                            text={'نه هیچ وقت! اولین باره تصمیمم جدی شده'} width={300}/></div>
                </Radio.Group>

            }
            <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '1rem'
            }}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default FourteenthStep