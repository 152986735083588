import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Radio} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const EighteenthStep = ({question}) =>{

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/19')
        }else{
            navigate('/questions/19')
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/17')
        } else {
            navigate('/questions/17')
        }
    }


    const onChange = (e) => {
        setInfo({...info,marital_status:e.target.value})
    };

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={info.marital_status}>
                <div><CustomRadioButton  value={'1'} text={'مجرد هستم'} width={350} /></div>
                <div><CustomRadioButton  value={'2'} text={'متاهل هستم'} width={350} /></div>
                <div><CustomRadioButton  value={'3'} text={'از همسرم رسما جدا شده ام'} width={350} /></div>
                <div><CustomRadioButton  value={'4'} text={'همسرم فوت شده است'} width={350} /></div>
                <div><CustomRadioButton  value={'5'} text={'در رابطه هستم ولی مدرک رسمی ندارم'} width={350} /></div>
            </Radio.Group>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default EighteenthStep