import {Input} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const FourthStep = ({question}) => {

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const keyPressHandler = (e) => {
        if (e.key === "Enter" && e.target.value) {
            setInfo({...info, age: e.target.value})
            if (location.pathname.includes('partner')) {
                navigate('/questions/partner/5')
            } else {
                navigate('/questions/5')
            }
        }
    }


    const changeInfoHandler = (e) => {
        setInfo({...info, age: e.target.value})
    }

    const nextPageHandler = () => {
        if (info.age) {
            if (location.pathname.includes('partner')) {
                navigate('/questions/partner/5')
            } else {
                navigate('/questions/5')
            }
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/3')
        } else {
            navigate('/questions/3')
        }
    }

    return (
        <div>
            <div>{question}</div>
            <Input placeholder={'سن خود را وارد کنید'} name={'age'} value={info.age} size={'large'}
                   onChange={changeInfoHandler}
                   onKeyPress={keyPressHandler}
            />
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem',backgroundColor:`${info.age ? '#32B8C5':'#ADF2F8'}`}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default FourthStep