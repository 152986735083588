import CustomRadioButton from "../../general/CustomRadioButton";
import {useEffect, useState} from "react";
import {Radio} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const SixthStep = ({question}) =>{

    const [value, setValue] = useState('');
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (value==='no' || info.ielts_test==='no') {
            if (location.pathname.includes('partner')){
                navigate('/questions/partner/8')
            }else{
                navigate('/questions/8')
            }
        }else{
            if (location.pathname.includes('partner')){
                navigate('/questions/partner/7')
            }else{
                navigate('/questions/7')
            }
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/5')
        } else {
            navigate('/questions/5')
        }
    }


    const onChange = (e) => {
        setInfo({...info,ielts_test:e.target.value})
        setValue(e.target.value);
    };

    useEffect(()=>{
        if (value==='yes' || info.ielts_test==='yes') {
            document.addEventListener('keypress',(e)=>{
                if (e.key === "Enter") {
                    if (location.pathname.includes('partner')) {
                        navigate('/questions/partner/7')
                    } else {
                        navigate('/questions/7')
                    }
                }
            })
        }

        if (value==='no' || info.ielts_test==='no') {
            document.addEventListener('keypress',(e)=>{
                if (e.key === "Enter") {
                    if (location.pathname.includes('partner')) {
                        navigate('/questions/partner/8')
                    } else {
                        navigate('/questions/8')
                    }
                }
            })
        }

        return ()=>document.removeEventListener('keypress',(e)=>{
            if (value==='yes' || info.ielts_test==='yes') {
                document.addEventListener('keypress',(e)=>{
                    if (e.key === "Enter") {
                        if (location.pathname.includes('partner')) {
                            navigate('/questions/partner/7')
                        } else {
                            navigate('/questions/7')
                        }
                    }
                })
            }

            if (value==='no' || info.ielts_test==='no') {
                document.addEventListener('keypress',(e)=>{
                    if (e.key === "Enter") {
                        if (location.pathname.includes('partner')) {
                            navigate('/questions/partner/8')
                        } else {
                            navigate('/questions/8')
                        }
                    }
                })
            }
        })
    },[])

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={info.ielts_test}>
                <div><CustomRadioButton isChecked={info.ielts_test==='yes'} value={'yes'} text={'بله'} width={150} /></div>
                <div><CustomRadioButton isChecked={info.ielts_test==='no'} value={'no'} text={'خیر'} width={150} /></div>
            </Radio.Group>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default SixthStep