import {useCallback, useEffect, useId, useState} from "react";
import CustomButton from "../../general/CustomButton";
import {Row, Col, Checkbox, Modal, Select, List} from 'antd'
import {DeleteOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import {StoreInformationUrl, years} from "../../../utils/constants";
import request from "../../../utils/request";
import Bcpnp from "../../../images/bc-pnp-icon.svg";
import ExpressEntry from "../../../images/express-entry-icon.svg";
import Info from "../../../images/info.svg";
import { v4 as uuidv4 } from 'uuid';

const previewText = (
    <>
        <div>
            ببین واقعیتش اینه که بعضی شغل ها تو کانادا کنترل شده هستن. ینی مجوز کار میخواد و از ایران نمیشه مستقیم بیای
            و اون کارو انجام بدی ولی بعضی رشته های دیگه هست که اگه توش مدرک و یا ساقه و یا هر جفتش رو داشته باشی از نظر
            مهاجرتی حتی شاید بشه خیلی راحت تر برات اقدام کنم و زودتر هم به نتیجه برسه.
        </div>
        <br/>
        <div>
            به فهرست شغل های زیر نگاه کن، آیا شغلی هست که توش سابقه کار داشته باشی و بتونی براش مدرک فنی حرفه ای یا نامه
            رسمی از سازمان معتبر ارائه بدی؟ توجه داشته باش که شرکت یا سازمان مربوطه باید معتبر و ثبت شده باشه.
        </div>
        <br/>
    </>
)


const iconList =[Bcpnp,ExpressEntry]

const EleventhStep = ({question}) => {

    const [isPreview, setIsPreview] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [showResultModal, setResultShowModal] = useState(false)
    const [result,setResult]=useState(null)
    const [selectedValues, setSelectedValues] = useState(null)
    const [jobInfo, setJobInfo] = useState({
        code_reshte: null,
        az_sal: null,
        ta_sal: null,
        type: null,
        year_of_acquisition: null
    })
    const [selectedId, setSelectedId] = useState(null)
    const [modalTitle, setModalTitle] = useState('')

    const {info, setInfo, data} = useOutletContext()

    const navigate = useNavigate()
    const location = useLocation()


    useEffect(()=>{
        if (modalTitle) {
            console.log('effect')
            setShowModal(true)
        }
    },[modalTitle])

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/12')
        } else {
            navigate('/questions/12')
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/10')
        } else {
            navigate('/questions/10')
        }
    }

    const handleSubmit = ()=>{
        request.post(StoreInformationUrl,info).then(res=>{
            setResult(res.data.data)
            // navigate('/buy-service',{state:res.data.data})
            console.log('store',res.data.data)
        })
    }


    const renderModalTitle = () => {
        const item = data?.certificate_major?.find(item => item.code === selectedId)
        return item?.persian_title
    }

    const previewHandler = () => {
        setIsPreview(false)
    }


    const handleCancel = () => {
        setShowModal(false);
        setSelectedId(null)
    };

    const deleteListItemHandler = (uid) => {
        let items = info.certificate.filter(item => item.uid !== uid)
        setInfo({...info,certificate: items})
    }


    const fromYearChange = (value) => {
        setJobInfo({...jobInfo, az_sal: value})
    }
    const toYearChange = (value) => {
        setJobInfo({...jobInfo, ta_sal: value})
    }
    const getCertificationYearChange = (value) => {
        setJobInfo({...jobInfo, year_of_acquisition: value})
    }
    const handleJobChange = (e, value) => {
        const item = data?.certificate_majors?.find(item => item.code === value)?.persian_title
        setJobInfo({...jobInfo, code_reshte: value})
        setModalTitle(item)
        setSelectedId(value)
        // setShowModal(true)
    }
    const jobTypeChange = (value) => {
        setJobInfo({...jobInfo, type: value})
    };
    const addJobInfo = () => {
        setInfo({...info, certificate: [...info.certificate, {...jobInfo,uid:uuidv4()}]})
        setShowModal(false)
        setJobInfo({code_reshte: null, az_sal: null, ta_sal: null, type: null, year_of_acquisition: null})
    }
    const getTitleFromCode = (value) => {
        return data?.certificate_majors.find(item => item.code === value)?.persian_title
    }
    const getType = (value) => {
        if (value === '1') {
            return 'دیپلم فنی حرفه ای'
        }
        if (value === '2') {
            return 'گواهینامه'
        }
        if (value === '3') {
            return 'کارآموزی'
        }
    }
    const getFullYear = (value) => {
        return years.find(item => item.label.includes(value)).label
    }


    return (
        <div style={{direction: 'rtl'}}>
            <Modal title={modalTitle} onCancel={handleCancel} open={showModal} footer={false}>
                <div style={{fontSize: '12.5px', color: '#686868', marginBottom: '1rem'}}>لطفا اطلاعات زیر را کامل کن
                </div>
                <Row gutter={[12, 12]}>
                    <Col lg={12}>
                        <Select
                            size={'large'}
                            placeholder={'نوع مدرک'}
                            // defaultValue={6}
                            onChange={jobTypeChange}
                            options={[
                                {
                                    value: '1',
                                    label: 'دیپلم فنی حرفه ای',
                                },
                                {
                                    value: '2',
                                    label: 'گواهینامه',
                                },
                                {
                                    value: '3',
                                    label: 'کارآموزی',
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={12}>
                        <Select
                            size={'large'}
                            placeholder={'تاریخ اخذ'}
                            value={jobInfo.year_of_acquisition}
                            // defaultValue={5}
                            onChange={getCertificationYearChange}
                            options={years}
                        />
                    </Col>
                </Row>
                <div style={{fontSize: '12.5px', color: '#686868', margin: '1rem 0'}}>سابقه کار</div>
                <Row gutter={[12, 12]}>
                    <Col lg={12}>
                        <Select
                            size={'large'}
                            placeholder={'از سال'}
                            value={jobInfo.az_sal}
                            // defaultValue={5}
                            onChange={fromYearChange}
                            options={years}
                        />
                    </Col>
                    <Col lg={12}>
                        <Select
                            size={'large'}
                            placeholder={'تا سال'}
                            value={jobInfo.ta_sal}
                            // defaultValue={6}
                            onChange={toYearChange}
                            options={years}
                        />
                    </Col>
                </Row>
                <div style={{textAlign: 'left', margin: '2rem 0 1rem'}}>
                    <CustomButton text={'ثبت'} ghost={true} width={120} onClick={addJobInfo}/>
                </div>
            </Modal>

            <Modal title={null} centered={true} onCancel={()=>setResult(null)} open={result?.result_text} footer={false} width={'80%'}>
                <div className={'buy-service-container'}>
                    <h2 className={'buy-service-title'}>{result?.result_text?.title}</h2>
                    <p style={{fontSize: '16px', color: '#777777', fontWeight: '400'}}>
                        {result?.result_text?.msg1}
                    </p>
                    {result?.result_text?.suggestions.map((item,index)=>{
                        return <div key={index}>
                            {/*<img src={iconList[index]} alt={'bc-pnp-icon'}/> */}
                            {item}</div>
                    })}
                    {/*<p style={{fontSize: '16px', color: '#777777', fontWeight: '400', marginTop: '1.5rem'}}>*/}
                    {/*    حالا اگه علاقمند هستی که در مورد مسیرهایی (مسیری) که گفتم بیشتر بدونی، یکی از سرویسهای مشاوره ای ما رو*/}
                    {/*    انتخاب کن تا همه جزئیات شون رو به طور کامل بهت توضیح بدم.*/}
                    {/*</p>*/}
                    {/*<p style={{fontSize: '16px', color: '#777777', fontWeight: '400', marginTop: '1.5rem'}}>*/}
                    {/*    <img src={Info} alt={'info-icon'} style={{marginLeft:'10px'}} />*/}
                    {/*    ضمنا بعضی وقتها اضافه کردن همسرت به پرونده، میتونه تو محاسبه امتیازها به نفعت باشه. به خاطر همین ما*/}
                    {/*    پیشنهاد می کنیم، حتی اگه قصدشو نداری که همزمان همسرت هم باهات مهاجرت کنه، اطلاعاتش رو تکمیل کن تا ما اگه*/}
                    {/*    گزینه های بهتری هم به ذهنمون رسید بهت بگیم.*/}
                    {/*</p>*/}
                    {/*<div style={{textAlign: 'right', marginTop: '1rem', fontSize: '18px', color: '#7E7E7E'}}><Checkbox>بررسی*/}
                    {/*    کامل پرونده برای مسیر استانی بریتیش کلمبیا</Checkbox></div>*/}
                    {/*<div style={{textAlign: 'right', marginTop: '1rem'}}><Checkbox>بررسی کامل پرونده برای مسیر اکسپرس*/}
                    {/*    انتری</Checkbox></div>*/}
                    {/*<div style={{textAlign: 'right', marginTop: '1rem'}}><Checkbox>اضافه کردن همسر به پرونده</Checkbox></div>*/}
                    {/*<div style={{width: '100%', textAlign: "left"}}><CustomButton text={'خرید سرویس'} width={150}*/}
                    {/*                                                              style={{marginTop: '1rem'}}/>*/}
                    {/*</div>*/}
                </div>
            </Modal>


            {isPreview ? <div>
                    {previewText}
                    <div style={{textAlign: 'left', marginBottom: '2rem'}}>
                        <CustomButton text={'فهرست شغل ها'} width={150} ghost={true} onClick={previewHandler}/>
                    </div>
                </div> :
                // <Checkbox.Group
                //     style={{
                //         width: '100%',
                //     }}
                //     onChange={onChangeGroup}
                // >
                    <Row>
                        {data?.certificate_majors?.map(item => {
                            return <Col lg={12} key={item.id}>
                                <div style={{color: '#7E7E7E', fontSize: '16px',cursor:'pointer'}}
                                          onClick={(e) => handleJobChange(e, item?.code)}
                                ><PlusSquareOutlined style={{color:'#FFC13F',marginLeft:'10px'}} />{item?.persian_title}</div>
                            </Col>
                        })}
                    </Row>
                // </Checkbox.Group>
            }
            {info?.certificate?.length > 0 && !isPreview && <div
                id="scrollableDiv"
                style={{
                    direction: 'ltr',
                    height: 100,
                    marginTop: '1rem',
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
            >
                <List
                    dataSource={info?.certificate}
                    bordered={false}
                    renderItem={(item) => (
                        <List.Item>
                            <div className={'list-item-wrapper'}>
                                <div>
                                    <div style={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#666666'
                                    }}>{getTitleFromCode(item.code_reshte)}</div>
                                    <span style={{
                                        fontSize: '13px',
                                        fontWeight: '400',
                                        color: '#929292'
                                    }}> نوع مدرک: </span>
                                    <span style={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        color: '#797979'
                                    }}>{getType(item.type)}</span>
                                </div>
                                <div className={'delete-date-container'}>
                                    <div style={{direction:'rtl',textAlign:'left'}}>

                                        <div>{getFullYear(item.ta_sal) + " - " + getFullYear(item.az_sal)}</div>
                                        <div>{getFullYear(item.year_of_acquisition)}</div>
                                    </div>
                                    <div id={'delete'} onClick={() => deleteListItemHandler(item.uid)}><DeleteOutlined
                                        className={'delete-icon'}/></div>
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            </div>}
            {/*<div style={{width: '100%', textAlign: "left"}}><CustomButton text={'تایید نهایی'} width={150}*/}
            {/*                                                              style={{marginTop: '1rem'}}*/}
            {/*                                                              onClick={handleSubmit}/>*/}
            {/*</div>*/}
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
            </div>
        </div>
    )
}

export default EleventhStep