import CustomRadioButton from "../../general/CustomRadioButton";
import {useEffect, useState} from "react";
import {Radio} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const EighthStep = ({question}) => {

    const [value, setValue] = useState(0);
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/9')
        } else {
            navigate('/questions/9')
        }
    }

    const prevPageHandler = ()=>{
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/7')
        } else {
            navigate('/questions/7')
        }
    }

    const onChange = (e) => {
        setInfo({...info, ielts_best_score: e.target.value})
    };

    useEffect(()=>{
        document.addEventListener('keypress',(e)=>{
            if (e.key === "Enter") {
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/9')
                } else {
                    navigate('/questions/9')
                }
            }
        })
        return ()=>document.removeEventListener('keypress',(e)=>{
            if (e.key === "Enter") {
                if (location.pathname.includes('partner')) {
                    navigate('/questions/partner/9')
                } else {
                    navigate('/questions/9')
                }
            }
        })
    },[])


    return (
        <div style={{textAlign: 'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={info.ielts_best_score}>
                <div><CustomRadioButton isChecked={info.ielts_best_score === '1'} value={'1'}
                                        text={'عمرا بتونم بالای 4 بگیرم'} width={350}/></div>
                <div><CustomRadioButton isChecked={info.ielts_best_score === '2'} value={'2'}
                                        text={'شاید اگه بخونم بتونم بین 4 تا 6 بگیرم'} width={350}/></div>
                <div><CustomRadioButton isChecked={info.ielts_best_score === '3'} value={'3'}
                                        text={'قطعا یکم بخونم 6 به بالا میشم'} width={350}/></div>
                <div><CustomRadioButton isChecked={info.ielts_best_score === '4'} value={'4'}
                                        text={'اگه بخونم شاید حتی 7 یا بالاتر هم بگیرم'} width={350}/></div>
                <div><CustomRadioButton isChecked={info.ielts_best_score === '5'} value={'5'}
                                        text={'اصلا تو چیکار داری، هر نمره ای بگی من میگیرم!'} width={350}/></div>
            </Radio.Group>
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default EighthStep