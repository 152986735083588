import stepRenderer from "../helpers/stepRenderer";
import {questions} from "../helpers/questions"
import {useEffect, useState} from "react";
import CustomButton from "../general/CustomButton";
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import './mainForm.scss'
import {Tooltip} from "antd";
import {Outlet, useLocation, useNavigate, useParams} from "react-router";
import CanadaFlag from '../../images/Canada-flag.svg'
import request from "../../utils/request";
import {InitUrl} from "../../utils/constants";
import MultiStepProgressBar from '../general/MultiStepProgressBar'

const initialData = {
    name: '',
    email: '',
    phone: '',
    age: '',
    first_language: '',
    ielts_test: 'no',
    ielts_score: null,
    ielts_best_score: '1',
    highest_level_of_education: [],
    work_experience: [],
    certificate: [],
    financial_ability_tamakon_banki: null,
    financial_ability_melk_va_zamin: null,
    financial_ability_stocks: null,
    financial_ability_sar_ghofli: null,
    financial_ability_sherkat: null,
    financial_ability_gardesh_mali: null,
    financial_ability_daraie_ghir_naghdi: null,
    financial_ability_nadaram : null,
    nationally_known_personality: 'no',
    apply_for_canada: null,
    residence_in_another_country: null,
    foreign_travel: null,
    family_in_canada: null,
    marital_status: '1',
    having_a_child: null,
    take_your_family_with_you: '1',
    apply_for_spouse: '2'
}

const MainForm = () => {
    const [info, setInfo] = useState(initialData)
    const [result, setResult] = useState(null)
    const [phone, setPhone] = useState('')
    const [data, setData] = useState(null)
    const [lastDegree, setLastDegree] = useState('')
    const [doctorate, setDoctorate] = useState({education_level: '', code_reshte: '', year_of_graduation: ''})
    const [master, setMaster] = useState({education_level: '', code_reshte: '', year_of_graduation: ''})
    const [bachelor, setBachelor] = useState({education_level: '', code_reshte: '', year_of_graduation: ''})
    const [associate, setAssociate] = useState({education_level: '', code_reshte: '', year_of_graduation: ''})
    const [secondary, setSecondary] = useState({education_level: '', code_reshte: '', year_of_graduation: ''})
    const [primary, setPrimary] = useState({education_level: '', code_reshte: '', year_of_graduation: ''})
    const [canNavigate, setCanNavigate] = useState(false)
    const [hasChild,setHasChild]=useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const param = useParams()

    console.log('info', info)

    useEffect(() => {
        request.get(InitUrl).then(res => {
            setData(res.data.data)
        })
    }, [])


    const nextPageHandler = (page) => {
        // if (canNavigate) {
        if (+param.step === 6 && info.ielts_test === 'no') {
            navigate(`/questions/${+param.step + 2}`)
        }else {
            if (location.pathname.includes('partner')) {
                navigate(`/questions/partner/${+param.step + 1}`)
                // setPage(currentPage => currentPage + 1)
            } else {
                navigate(`/questions/${+param.step + 1}`)
                // setPage(currentPage => currentPage + 1)
            }
        }
        // }

    }

    const prevPageHandler = (page) => {
        if (+param.step === 8 && info.ielts_test === 'no') {
            navigate(`/questions/${+param.step - 2}`)
        } else {
            if (+param.step > 1 && location.pathname.includes('partner')) {
                navigate(`/questions/partner/${+param.step - 1}`)
                // setPage(currentPage => currentPage - 1)
            } else if (+param.step > 1 && !location.pathname.includes('partner')) {
                navigate(`/questions/${+param.step - 1}`)
                // setPage(currentPage => currentPage - 1)
            }
        }
    }

    return (
        <div className={'main-form-container'}>
            <div className={'main-form-items-wrapper'}>
                <div className={'main-form-progress'}>
                    <div>
                        <img src={CanadaFlag} alt={'Canada flag'} style={{marginRight: '20px'}}/>
                    </div>
                    <div style={{width: '100%'}}>
                        <progress id="progress" value="50" max="100"> 32%</progress>
                        {location.pathname.includes('partner') &&
                        <progress id="progress" value="50" max="100"> 32%</progress>}
                        {/*<MultiStepProgressBar step={param.step} />*/}
                    </div>
                </div>
                <div className={'main-form-question'}>
                    <div className={'question-text'}> {+param.step}. {questions[+param.step - 1].text}</div>
                    {/*<div style={{width: '100%'}}>{stepRenderer(page)}</div>*/}
                    <div style={{width: '100%'}}>
                        <Outlet
                            context={
                                {
                                    info,
                                    setInfo,
                                    canNavigate,
                                    setCanNavigate,
                                    phone,
                                    setPhone,
                                    data,
                                    lastDegree,
                                    setLastDegree,
                                    doctorate,
                                    setDoctorate,
                                    master,
                                    setMaster,
                                    bachelor,
                                    setBachelor,
                                    associate,
                                    setAssociate,
                                    secondary,
                                    setSecondary,
                                    primary,
                                    setPrimary,
                                    hasChild,
                                    setHasChild
                                }
                            }/>
                    </div>
                    {/*<div style={{width: '100%'}}><CustomButton text={page===21 ? 'تایید نهایی':'مرحله بعد'} width={150}*/}
                    {/*                                           style={{marginTop: '1rem'}} onClick={nextPageHandler}/>*/}
                    {/*</div>*/}
                </div>
            </div>
            {/*<div className={'main-form-footer'}>*/}
            {/*    <div className={'question-handler-container'}>*/}
            {/*        <Tooltip title={'سوال بعدی'}>*/}
            {/*            <div className={'next-question'} onClick={nextPageHandler}><DownOutlined/></div>*/}
            {/*        </Tooltip>*/}
            {/*        <Tooltip title={'سوال قبلی'}>*/}
            {/*            <div className={'prev-question'} onClick={prevPageHandler}><UpOutlined/></div>*/}
            {/*        </Tooltip>*/}
            {/*    </div>*/}
            {/*    <div className={'footer-question'}>{questions?.[+param.step]?.text}</div>*/}
            {/*</div>*/}
        </div>
    )
}

export default MainForm