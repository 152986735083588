import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://immigrationdev.academiahire.com/api",
});

axiosInstance.interceptors.request.use(
    function (config) {
        const token = '5|ffbzWgXDbTnCQLkeIkJHq4oLiIdLRA39LzmBsnEi'
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default axiosInstance