import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, List, Modal, Radio, Row, Select} from "antd";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import {years} from "../../../utils/constants";
import {v4 as uuidv4} from "uuid";

const {Option} = Select

const FifteenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [resInfo, setResInfo] = useState({country_code: null, az_sal: null, ta_sal: null})
    const [showQuestion, setShowQuestion] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const {info, setInfo, data} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/16')
        } else {
            navigate('/questions/16')
        }
    }

    const prevPageHandler = () => {
        if (location.pathname.includes('partner')) {
            navigate('/questions/partner/14')
        } else {
            navigate('/questions/14')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'no') {
            setShowModal(true)
        } else {
            setShowQuestion(true)
        }
    };

    const handleOk = () => {
        setValue('yes')
    };

    const handleCancel = () => {
        setShowModal(false);
        setValue(null)
    };

    const deleteListItemHandler = (id) => {
        // let items = data.filter(item => item.id !== id)
        // setData(items)
        let items = info?.residence_in_another_country?.filter(item => item.id !== id)
        setInfo({...info, residence_in_another_country: items?.length > 0 ? items : null})
    }


    const filterOption = (input, option) => {
        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const countryChangeHandler = (code) => {
        setResInfo({...resInfo, country_code: String(code)})
    }

    const fromYearChange = (year) => {
        setResInfo({...resInfo, az_sal: year})
    }

    const toYearChange = (year) => {
        setResInfo({...resInfo, ta_sal: year})
    }

    const addInfo = () => {
        info?.residence_in_another_country ?
            setInfo({...info, residence_in_another_country: [...info?.residence_in_another_country, {id: uuidv4(), ...resInfo}]}) :
            setInfo({...info, residence_in_another_country: [{id: uuidv4(), ...resInfo}]})
        setResInfo({country_code: null, az_sal: null, ta_sal: null})
    }

    const getCountryFromCode = (value) => {
        return data?.countries?.find(item => String(item.id) == value)?.title
    }

    const getFullYear = (value) => {
        return years.find(item => item.label.includes(value)).label
    }


    return (
        <div style={{textAlign: 'right'}}>
            <div>{question}</div>
            {showQuestion || info?.residence_in_another_country ?  <>
                    <div style={{fontSize: '12.5px', color: '#686868', marginBottom: '1rem'}}>لطفا اطلاعات زیر را کامل
                        کن
                    </div>
                    <Row gutter={[12, 12]}>
                        <Col lg={24}>
                            <Select
                                size={'large'}
                                placeholder={'انتخاب کشور'}
                                optionFilterProp="children"
                                showSearch
                                value={getCountryFromCode(resInfo?.country_code)}
                                filterOption={filterOption}
                                onChange={countryChangeHandler}
                            >
                                {data?.countries.map(item => {
                                    return <Option key={item.id} value={item.id}>{item.title}</Option>
                                })}
                            </Select>
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'از سال'}
                                value={resInfo?.az_sal}
                                onChange={fromYearChange}
                                options={years}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'تا سال'}
                                value={resInfo?.ta_sal}
                                onChange={toYearChange}
                                options={years}
                            />
                        </Col>
                    </Row>
                    <div style={{textAlign: 'left', margin: '2rem 0 1rem'}}>
                        <CustomButton text={'افزودن اقامت جدید'} ghost={true} width={150} onClick={addInfo}/>
                    </div>
                    {info?.residence_in_another_country?.length > 0 && <div
                        id="scrollableDiv"
                        style={{
                            direction: 'ltr',
                            minHeight: 'fit-content',
                            maxHeight: 150,
                            margin: '2rem 0',
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                        <List
                            dataSource={info?.residence_in_another_country}
                            bordered={false}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className={'list-item-wrapper'}>
                                        <div>
                                            <div style={{fontWeight:'bold'}}>{getCountryFromCode(item.country_code)}</div>
                                        </div>
                                        <div className={'delete-date-container'}>
                                            <div>{getFullYear(item.az_sal)}</div>
                                            <span style={{margin:'0 5px'}}>-</span>
                                            <div>{getFullYear(item.ta_sal)}</div>
                                            <div id={'delete'} onClick={() => deleteListItemHandler(item.id)}>
                                                <DeleteOutlined className={'delete-icon'}/></div>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>}
                </>
                : <Radio.Group onChange={onChange} value={value}>
                <div><CustomRadioButton isChecked={value === 'yes'} value={'yes'} text={'بله'} width={300}/></div>
                <div><CustomRadioButton isChecked={value === 'no'} value={'no'}
                                        text={'نه متاسفانه!!'} width={300}/></div>
            </Radio.Group>

            }
            <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '1rem'
            }}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={nextPageHandler}
                />
                <CustomButton
                    text={'مرحله قبل'}
                    ghost={true}
                    width={150}
                    style={{marginTop: '1rem'}}
                    onClick={prevPageHandler}
                />
            </div>
        </div>
    )
}

export default FifteenthStep