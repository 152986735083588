import {Input} from "antd";
import CustomButton from "../../general/CustomButton";
import {useOutletContext, useNavigate, useLocation} from "react-router";
import {useEffect} from "react";

const FirstStep = ({question}) => {

    const {info, setInfo,canNavigate,setCanNavigate} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const changeInfoHandler = (e) => {
        setInfo({...info, name: e.target.value})
        setCanNavigate(true)
    }

    const keyPressHandler = (e)=> {
        if (e.key==="Enter" && e.target.value){
            // setInfo({...info, name: e.target.value})
            if (location.pathname.includes('partner')) {
                navigate('/questions/partner/2')
            } else {
                navigate('/questions/2')
            }
        }
    }

    useEffect(()=>{
        return ()=>setCanNavigate(false)
    },[])


    const nextPageHandler = () => {
        if (info.name){
            if (location.pathname.includes('partner')) {
                navigate('/questions/partner/2')
            } else {
                navigate('/questions/2')
            }
        }
    }


    return (
        <div>
            <div>{question}</div>
            <Input placeholder={'اسم خود را وارد کنید'} name={'name'} size={'large'} value={info.name}
                   onKeyPress={keyPressHandler}
                   onChange={changeInfoHandler}/>
            {/*<div style={{width: '100%'}}><CustomButton text={page === 21 ? 'تایید نهایی' : 'مرحله بعد'} width={150}*/}
            <div style={{width: '100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'1rem'}}>
                <CustomButton
                    text={'مرحله بعد'}
                    width={150}
                    style={{marginTop: '1rem',backgroundColor:`${info.name ? '#32B8C5':'#ADF2F8'}`}}
                    onClick={nextPageHandler}
                />
            </div>
        </div>
    )
}

export default FirstStep